<template>
    <div>
        <SiteHeader style="width: 100%;"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <div style="margin-top: 50px;">
            <center><h1 class="ssa-text-color" style="font-family: Arial; font-size: 3rem;"><strong>INSTITUTIONAL OFFICE</strong></h1></center>
        </div>
        <div style="margin-top: 50px;">
            <div class="row" style="margin-left: 15px;">
                <div class="col-md-6">
                    <el-collapse>
                        <el-collapse-item name="1" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;ARCHIVES OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>Our Mission and Vision</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                    do eiusmod tempor incididunt ut labore et dolore magna
                                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                    occaecat cupidatat non proident, sunt in culpa qui officia
                                    deserunt mollit anim id est laborum.
                                </span>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Key Functions</strong></span>
                                        <br>
                                        <span class="ssa-text-color" style="font-family: Arial;">1. Function 1</span>
                                        <br>
                                        <span class="ssa-text-color" style="font-family: Arial;">2. Function 2</span>
                                        <br>
                                        <span class="ssa-text-color" style="font-family: Arial;">3. Function 3</span>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.110rem;"><strong>Contact Us</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>archives@ssc.edu.ph</strong></span>
                                        <br>
                                        <i class="fa fa-phone-square"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>8567 - 7686 (local ####)</strong></span>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="3" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;ADMINISTRATIVE AND GENERAL SERVICES OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>VISION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;AGSO envisions a safe, comfortable, clean, green, orderly and well-maintained learning and
                                    working environment adhering to the architectural integrity of the Institution.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>MISSION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;The Administrative and General Services Office (AGSO) provides prompt and efficient delivery
                                    of services in housekeeping, plumbing, carpentry and electrical needs of the Institution. The
                                    AGSO is likewise tasked to oversee and supervise the school’s preventive maintenance
                                    program and assist in the various outsourced building construction projects.
                                </span>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Key Functions</strong></span>
                                        <ol style="font-family: Arial;" class="ssa-text-color">
                                            <li>Prepare work plans with work schedules for housekeeping, plumbing, carpentry and electrical services</li>
                                            <li>Provide prompt and efficient delivery of AGSO services in the preparation and implementation of scheduled activities and special events</li>
                                            <li>Assist in outsourced projects of the various units and departments</li>
                                            <li>Implement policies on the waste management program of the school</li>
                                            <li>Draw up and implement a preventive maintenance program</li>
                                            <li>Take responsibility for all keys to classrooms, offices, vehicles and special facilities</li>
                                            <li>Exercise stewardship in the management of equipment, tools and supplies in the delivery of AGSO services</li>
                                            <li>Draw up a plan to secure regular services of professionals to address structural, mechanical and electrical requirements of the Institution in coordination with the AGSO personnel</li>
                                        </ol>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.110rem;"><strong>Contact Us</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>agso@ssc.edu.ph</strong></span>
                                        <br>
                                        <i class="fa fa-phone-square"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>8567 - 7686 (local 8158 and 8159)</strong></span>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="5" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;ADMISSIONS OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>VISION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;The Admissions Office envisions itself as a provider of
                                    quality service in the intensive but selective recruitment
                                    and admission of students for the various academic units
                                    in accordance with the standards of the school. It is
                                    committed to achieving the school’s enrollment goals for
                                    new students while living up to the school’s Benedictine
                                    tradition of service to God and community.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>MISSION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;The Admissions Office dedicates itself in attracting,
                                    recruiting and admitting students who are promising in
                                    their academic, leadership and service orientation
                                    pursuits through providing the highest quality of service
                                    that fosters a customer-centric atmosphere, respectful
                                    and efficient professional services meant to assist and
                                    guide prospective and current students and their families
                                    as they go through the admissions process and explore
                                    the learning opportunities that St. Scholastica’s College
                                    Manila can offer.
                                </span>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Key Functions</strong></span>
                                        <ul style="font-family: Arial;" class="ssa-text-color">
                                            <li>Admissions and Recruitment</li>
                                        </ul>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.110rem;"><strong>Contact Us</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>sscadmit@ssc.edu.ph</strong></span>
                                        <br>
                                        <i class="fa fa-mobile"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>0968 610 9119</strong></span>
                                        <br>
                                        <i class="fa fa-phone-square"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>(02) 8567 7686 loc. 8169</strong></span>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="7" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;BUSINESS OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="9" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;CAREER AND PLACEMENT OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="10" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;CENTER FORT CONTINUING EDUCATION AND DEVELOPMENT (CCED)</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>VISION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;A leading center for continuing education for various
                                    stakeholders of St. Scholastica’s College Manila that
                                    offers life-long learning programs.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>MISSION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;We are committed to providing life-long education
                                    and training programs to various individuals,
                                    organizations and communities that address
                                    improved capabilities and skills for employment and
                                    self-development for citizenship and/or social responsibility.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>GOALS</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    The Center for Continuing Education and Development (CCED) of the College Unit aims to
                                </span>
                                <ol style="font-family: Arial;" class="ssa-text-color">
                                    <li>Provide opportunities for individuals and organizations / communities to improve one’s capabilities and competence through the special program offering.</li>
                                    <li>Establish partnership and networks with local and international organizations and academic institutions relative to the implementation of its programs.</li>
                                    <li>Develop innovative programs that will promote SSC as a lead provider of life-long learning education</li>
                                </ol>
                                <br>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Operating Hours</strong></span>
                                        <ul style="font-family: Arial;" class="ssa-text-color">
                                            <li>MON-FRI 7:30-4:30</li>
                                            <li>SAT 7:30-11:30</li>
                                        </ul>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.110rem;"><strong>Contact Us</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>cced@ssc.edu.ph</strong></span>
                                        <br>
                                        <i class="fa fa-phone-square"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>8567-7686 loc. 2296</strong></span>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="6" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;CENTER FOR RESEARCH AND EVALUATION</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="11" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;FOOD SERVICES AND BOOKSTORE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="13" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;GUIDANCE AND COUNSELING OFFICES</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="13" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;FRIENDENSHAUS HOTEL SCHOOL</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>VISION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;FHS as a leading Hotel School that showcases a rich
                                    German Benedictine heritage in terms of food, music and
                                    the arts  while maintaining a quality standard
                                    characterized by exceptional and personalized services
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>MISSION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;FHS is committed to provide a practicum venue for
                                    SHLRM students of St. Scholastica’s College Manila
                                    where the rich German tradition of the Congregation of
                                    the Missionary Benedictine Sisters of Tutzing, Germany
                                    and as advocates of peace, love for music and deep appreciation
                                    of the arts will be experienced.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>GOALS</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">The mission-vision of the FHS is realized through the following:</span>
                                <ul class="ssa-text-color" style="font-family: Arial;">
                                    <li>Be a venue for the industry immersion (practicum site) of BSHM and BSTM students of the School of Hotel;</li>
                                    <li>Leisure, and Restaurant Management of SSC where students gain hands-on experiences in various hospitality and tourism operations;</li>
                                    <li>Showcase a rich German Benedictine heritage in terms of food, music and the arts;</li>
                                    <li>Be a marketing arm of the SHLRM program as clients and hotel patrons experience the warm Benedictine hospitality through quality service.</li>
                                </ul>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>SERVICES</strong></span>
                                <ul style="list-style-type: none;" class="ssa-text-color">
                                    <li>Room Accommodation</li>
                                    <li>Dormitory for College Students and SSC Employees</li>
                                    <li>Coffee Shop</li>
                                    <li>Function Room</li>
                                    <li>Board Room</li>
                                    <li>Kitchen Demonstration Facilities</li>
                                    <li>Gym</li>
                                    <li>Spa</li>
                                    <li>Travel Desk</li>
                                    <li>Laundry</li>
                                </ul>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Staff</strong></span>
                                        <ul style="font-family: Arial;" class="ssa-text-color">
                                            <li>Prof. Lailie Uy-Sicdawag – Hotel Director</li>
                                            <li>Ms. Carlyn Bacalla – FHS Admin Secretary</li>
                                        </ul>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Operating Hours</strong></span>
                                        <ul style="font-family: Arial;" class="ssa-text-color">
                                            <li>8:00 AM-10:00PM</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.110rem;"><strong>Contact Us</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>Adminfhs@ssc.edu.ph</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>infofhs@ssc.edu,ph</strong></span>
                                        <br>
                                        <i class="fa fa-phone-square"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>8567-7686 loc.7110 or 7111</strong></span>
                                        <br>
                                        <i class="fa fa-home"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>2560 Leon Guinto Street corner Estrada Street, Malate, Maila. 1004</strong></span>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
                <div class="col-md-6">
                    <el-collapse>
                        <el-collapse-item name="15" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;HUMAN RESOURCE & DEVELOPMENT OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="17" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;INFORMATION & COMMUNICATION TECHNOLOGY OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>VISION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;The ICTO envisions to be an integral part of the academic and administrative thrusts
                                    toward 21st-century learning through an efficient and effective communication network and an
                                    efficient and effective school management system (e.g., student records, human resource records).
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>MISSION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;The Information and Communication Technology Office (ICTO) provides quality
                                    information technology and telecommunication services through the most cost-effective
                                    management of resources grounded on the Benedictine value of stewardship.
                                </span>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Key Functions</strong></span>
                                        <ol style="font-family: Arial;" class="ssa-text-color">
                                            <li>Development and Maintenance of Information Systems</li>
                                            <li>Software and Hardware Acquisition, Installation, Maintenance and Troubleshooting</li>
                                            <li>Student and Employee Services Management</li>
                                            <li>Management of Computer Laboratories</li>
                                            <li>Maintenance and Update of the SSC Website</li>
                                            <li>ICT Training and Staff Development</li>
                                        </ol>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.110rem;"><strong>Contact Us</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>icto@ssc.edu.ph</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>ictmanager@ssc.edu.ph</strong></span>
                                        <br>
                                        <i class="fa fa-phone-square"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>8567 - 7686 (local 8111)</strong></span>
                                        <br>
                                        <br>
                                        <span class="ssa-text-color" style="font-family: Arial;">Online Service Desk: <a href="https://bit.ly/ICTO-OSD">bit.ly/ICTO-OSD</a></span>
                                        <br>
                                        <span class="ssa-text-color" style="font-family: Arial;">Password Reset: <a href="https://bit.ly/SSCMlaForgotPassword">https://bit.ly/SSCMlaForgotPassword</a></span>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;MARKETING AND COMMUNICATIONS OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <!-- <el-collapse>
                        <el-collapse-item name="10" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;CENTER FORT CONTINUING EDUCATION AND DEVELOPMENT (CCED)</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>VISION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;A leading center for continuing education for various
                                    stakeholders of St. Scholastica’s College Manila that
                                    offers life-long learning programs.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>MISSION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;We are committed to providing life-long education
                                    and training programs to various individuals,
                                    organizations and communities that address
                                    improved capabilities and skills for employment and
                                    self-development for citizenship and/or social responsibility.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>GOALS</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    The Center for Continuing Education and Development (CCED) of the College Unit aims to
                                </span>
                                <ol style="font-family: Arial;" class="ssa-text-color">
                                    <li>Provide opportunities for individuals and organizations / communities to improve one’s capabilities and competence through the special program offering.</li>
                                    <li>Establish partnership and networks with local and international organizations and academic institutions relative to the implementation of its programs.</li>
                                    <li>Develop innovative programs that will promote SSC as a lead provider of life-long learning education</li>
                                </ol>
                                <br>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Operating Hours</strong></span>
                                        <ul style="font-family: Arial;" class="ssa-text-color">
                                            <li>MON-FRI 7:30-4:30</li>
                                            <li>SAT 7:30-11:30</li>
                                        </ul>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.110rem;"><strong>Contact Us</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>cced@ssc.edu.ph</strong></span>
                                        <br>
                                        <i class="fa fa-phone-square"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>8567-7686 loc. 2296</strong></span>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse> -->
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="12" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;MEDICAL & DENTAL HEALTH SERVICES</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="4" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;PLANNING AND QUALITY ASSURANCE OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>Vision</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    The Planning and Quality Assurance Office (PQAO) envisions an efficient and effective educational organizational management system (EOMS)
                                    for all school’s processes that employs data-based strategic planning process leading
                                    to excellent performance standards and continuous quality improvement.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>Mission</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    The Planning and Quality Assurance Office (PQAO) serves as the organizational unit of
                                    St. Scholastica’s College in maintaining quality in the delivery of educational programs and services.
                                    Additionally, the PQAO facilitates the strategic thinking and planning process leading to data-based strategic plans. 
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>Functions (Job Summary)</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    The Planning and Quality Assurance Director is the partner of the school’s academic and administrative officials
                                    in developing and implementing quality assurance and improving productivity in school operations.
                                    The Planning and Quality Assurance Director likewise facilitates the drawing up of institutional and sectoral
                                    plans and implementing an effective planning and performance monitoring system that will ensure a negotiated and
                                    communicated planning process consistent with the approved budget cycle that will provide an effective tool for evaluating performance. 
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial;">Email: rpulmones@ssc.edu.ph</span>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial;">Tel: 85677686 local 8291 (office assistant) and 8538 (PQAO Director)</span>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <!-- <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;INST’L QUALITY ASSURANCE OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse> -->
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="14" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;REGISTRAR’S OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="16" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;SAFETY AND SECURITY OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="18" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;STUDENT AFFAIRS OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>VISION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;We envision an effective and efficient delivery of Student
                                    Affairs Programs and Services supportive of the holistic
                                    formation and development of each Scholastican in the
                                    light of a Benedictine, Christian, and socially-oriented
                                    school.
                                </span>
                                <br>
                                <br>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>MISSION</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    &nbsp;&nbsp;The Student Affairs Office is an academic support office
                                    that seeks to contribute to the holistic formation and
                                    development of Scholasticans through relevant, quality,
                                    and high-impact programs and services that enhance
                                    Scholasticans’ educational experiences; develop
                                    them to be well-rounded and able to adapt to any milieu
                                    and be creative, resourceful, socially responsible,
                                    responsive, disciplined, and with unquestioned integrity.

                                </span>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Staff</strong></span>
                                        <ul style="font-family: Arial;" class="ssa-text-color">
                                            <li>Prof. Victor S. Vigo Jr. - Dean of Student Affairs</li>
                                            <li>Ms. Darlyn Nicolas - Office Staff</li>
                                        </ul>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Operating Hours</strong></span>
                                        <ul style="font-family: Arial;" class="ssa-text-color">
                                            <li>Monday - Friday: 7:30 AM - 4:30 AM</li>
                                            <li>Saturday: 8:00 - 12:00 NN</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Key Functions</strong></span>
                                        <ol style="font-family: Arial;" class="ssa-text-color">
                                            <li>
                                                a series of assemblies that aims to welcome Scholasticans
                                                to their second home via featured talks on the school’s vision-mission,
                                                thrust and theme, academic and non-academic policies, programs and services,
                                                campus tours and events designed to build community and develop school spirit.
                                                All freshwomen/men and other new students undergo a three-day Orientation Program,
                                                while upper-year levels hold annual Reorientation Programs.
                                                <br>
                                                <br>
                                                A highlight of the Orientation Program is the staging of Ora et Labora
                                                by Sining Tanghalan, the school’s resident theater group. Ora et
                                                Labora is a pageant on the lives of the twin saints – St. Benedict and
                                                St. Scholastica.
                                            </li>
                                            <li>
                                                a comprehensive leadership formation program that caters primarily to
                                                the student organization leaders, officers of the Student Council, and
                                                the class representatives.  It is composed of four modules: Module 1
                                                – Defining Benedictine Leadership in the light of the National
                                                Situation, Module 2 – Basic Leadership Skills, Module 3 –
                                                Community Organizing and Immersion, and Module 4 – Mentoring and
                                                Coaching.
                                                <br>
                                                <br>
                                                Out-of-school training programs are also availed of by student
                                                leaders with appropriate support extended by the SAO. These
                                                programs are sponsored by the student organizations’ affiliates, SSC
                                                consortia partners, or government/non-government organizations.
                                            </li>
                                            <li>
                                                a culminating activity that aims to recognize outstanding student leaders,
                                                organizations, and moderators during a particular school year.
                                                It also serves as the turnover ceremony for the Student Council
                                                and all accredited student organizations.
                                            </li>
                                        </ol>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Gallery</strong></span>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <a href="#" @click.prevent="showGallery(resources.student_affairs.student_orientation)">
                                                    <img :src="resources.student_affairs.student_orientation.cover" style="width: 100%; height: 100%;">
                                                </a>
                                            </div>
                                            <div class="col-md-4">
                                                <a href="#" @click.prevent="showGallery(resources.student_affairs.student_activity)">
                                                    <img :src="resources.student_affairs.student_activity.cover" style="width: 100%; height: 100%;">
                                                </a>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <a href="#" @click.prevent="showGallery(resources.student_affairs.scholastican_leadership)">
                                                    <img :src="resources.student_affairs.scholastican_leadership.cover" style="width: 100%; height: 100%;">
                                                </a>
                                            </div>
                                            <div class="col-md-4">
                                                <a href="#" @click.prevent="showGallery(resources.student_affairs.blue_and_white)">
                                                    <img :src="resources.student_affairs.blue_and_white.cover" style="width: 100%; height: 100%;">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="19" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;SCHOLARSHIPS AND GRANTS OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
        </div>
        <el-dialog
            :title="gallery.title"
            :visible.sync="showContent"
            width="50%">
            <el-carousel trigger="click" height="700px" :autoplay="false" indicator-position="none">
                <el-carousel-item v-for="(item, index) in gallery.pics" :key="index">
                    <img :src="item" style="height: 700px; width: 100%">
                </el-carousel-item>
            </el-carousel>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeGallery()">Close</el-button>
            </span>
        </el-dialog>
        <div style="margin-top: 75px;">
            &nbsp;
        </div>
        <SiteFooter :style="{backgroundImage: `url('${env_wp.APP_URL}/public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_backdrop.png')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%'}"></SiteFooter>
    </div>
</template>
<style>
    .el-collapse-item__header{
        display: flex;
        align-items: center;
        height: 48px;
        line-height: 48px;
        background-color: #012376;
        color: #ffffff;
        cursor: pointer;
        border-bottom: 1px solid #EBEEF5;
        font-size: 13px;
        font-weight: 500;
        transition: border-bottom-color .3s;
        outline: 0;
    }
    .el-collapse-item__content {
        padding-bottom: 25px;
        font-size: 13px;
        color: #303133;
        line-height: 1.769230769230769;
        margin-left: 25px;
    }
</style>
<script>
    import SiteHeader from "../../../components/general/SiteHeader";
    import NavbarStatic from "../../../components/general/NavbarStatic";
    import SiteFooter from "../../../components/general/SiteFooter";
    export default {
        props: {
            resources: Object,
        },
        components: { SiteHeader,NavbarStatic,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp,
                gallery: {},
                showContent: false,
            }
        },
        mounted() {

        },
        created() {
            this.addSsaColorClass();
        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            addSsaColorClass(){
                $(".el-collapse-item__header").css({
                    "background-color": "#012376",
                    "color": "#ffffff"
                });
            },
            showGallery(gallery){
                this.showContent = true;
                this.gallery = gallery;
            },
            closeGallery(){
               this.gallery = {};
               this.showContent = false; 
            }
        }
    }
</script>