<template>
    <div>
        <!--HEADER NAVBAR START-->
        <SiteHeader style="width: 100%"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <!--HEADER NAVBAR END-->

        <!--CONTENTS START-->
<!--        <div style="margin-top: 20px;">-->
<!--            <center>-->
<!--                <h1 style="font-size: 4rem;"><span style="color: #012376;"><strong>APPLICATIONS ARE STILL GOING</strong></span></h1>-->
<!--                <h4><span style="color: #012376;"><strong>PRE-SCHOOL&nbsp;&bull;&nbsp;GRADE SCHOOL&nbsp;&bull;&nbsp;HIGH SCHOOL&nbsp;&bull;&nbsp;COLLEGE&nbsp;&bull;&nbsp;GRADUATE SCHOOL</strong></span></h4>-->
<!--            </center>-->
<!--        </div>-->
        <div style="margin-top: 50px;">
            <el-carousel :interval="6500" indicator-position="outside" :height="isWindowOnDesktop ? '800px' : '250px'">
                <template v-for="(slider, index) in resources.sliders">
                    <el-carousel-item>
                        <img :src="slider" style="height: auto; width: 100%;">
                    </el-carousel-item>
                </template>
            </el-carousel>
        </div>

        <div style="margin-top: 20px;">
            <center>
                <h1 :style="isWindowOnDesktop ? 'font-size: 3.5rem;' : 'font-size: 2rem;'"><span style="color: #012376;"><strong>Welcome to St. Scholastica's College Manila!</strong></span></h1>
            </center>
            <template v-if="isWindowOnDesktop">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <a :href="env_wp.APP_URL+'/programs/pre-school'">
                                <div class="card" style="width: 15rem; border-radius: 20px;">
                                    <el-image style="border-radius: 20px; width: 238px; height: 160px;" class="card-img-top" :src="resources.link_images.pre_school" alt="Pre-School" lazy></el-image>
                                    <div class="overlay"></div>
                                    <center><div class="centered-text">Preschool and Grade School</div></center>
                                </div>
                            </a>
                        </div>

                        <div class="col-md-3">
                            <a :href="env_wp.APP_URL+'/programs/high-school'">
                                <div class="card" style="width: 15rem; border-radius: 20px;">
                                    <el-image style="border-radius: 20px; width: 238px; height: 160px;" class="card-img-top" :src="resources.link_images.jhs" alt="Junior High" lazy></el-image>
                                    <div class="overlay"></div>
                                    <center><div class="centered-text">Junior High School</div></center>
                                </div>
                            </a>
                        </div>

                        <div class="col-md-3">
                            <a :href="env_wp.APP_URL+'/programs/high-school'">
                                <div class="card" style="width: 15rem; border-radius: 20px;">
                                    <el-image style="border-radius: 20px; width: 238px; height: 160px;" class="card-img-top" :src="resources.link_images.shs" alt="Senior High" lazy></el-image>
                                    <div class="overlay"></div>
                                    <center><div class="centered-text">Senior High School</div></center>
                                </div>
                            </a>
                        </div>

                        <div class="col-md-3">
                            <a :href="env_wp.APP_URL+'/programs/night-secondary-school'">
                                <div class="card" style="width: 15rem; border-radius: 20px;">
                                    <el-image style="border-radius: 20px; width: 238px; height: 160px;" class="card-img-top" :src="resources.link_images.night_secondary" alt="Night Secondary School" lazy></el-image>
                                    <div class="overlay"></div>
                                    <center><div class="centered-text">Night Secondary School</div></center>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="container" style="margin-top: 30px;">
                    <div class="row">
                        <div class="col-md-3">
                            <a :href="env_wp.APP_URL+'/programs/college'">
                                <div class="card" style="width: 15rem; border-radius: 20px;">
                                    <el-image style="border-radius: 20px; width: 238px; height: 160px;" class="card-img-top" :src="resources.link_images.college" alt="College" lazy></el-image>
                                    <div class="overlay"></div>
                                    <center><div class="centered-text">College</div></center>
                                </div>
                            </a>
                        </div>

                        <div class="col-md-3">
                            <a :href="env_wp.APP_URL+'/programs/graduate-school'">
                                <div class="card" style="width: 15rem; border-radius: 20px;">
                                    <el-image style="border-radius: 20px; width: 238px; height: 160px;" class="card-img-top" :src="resources.link_images.grad_school" alt="Graduate School" lazt></el-image>
                                    <div class="overlay"></div>
                                    <center><div class="centered-text">Graduate School</div></center>
                                </div>
                            </a>
                        </div>

                        <div class="col-md-3">
                            <div class="card" style="width: 15rem; border-radius: 20px;">
                                <el-image style="border-radius: 20px; width: 238px; height: 160px;" class="card-img-top" :src="resources.link_images.cognate" alt="Cognate Programs" lazy></el-image>
                                <div class="overlay"></div>
                                <center><div class="centered-text">Cognate Programs</div></center>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="card" style="width: 15rem; border-radius: 20px;">
                                <el-image style="border-radius: 20px; width: 238px; height: 160px;" class="card-img-top" :src="resources.link_images.ced" alt="CCED" lazy></el-image>
                                <div class="overlay"></div>
                                <center><div class="centered-text">Center for Continuing Education and Development</div></center>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container" style="margin-top: 30px;">
                    <div class="row justify-content-center">
                        <div class="col-md-3">
                            <a href="https://sscmanila.orangeapps.ph/landing/sscmanila/" class="rounded-btn btn btn-ssa"><span style="color: white; font-size: 28px;">APPLY NOW</span></a>
                        </div>
                        <div class="col-md-3">
                            <a :href="env_wp.APP_URL+'/admission'" class="rounded-btn btn btn-ssa" style="margin-left: 5px;"><span style="color: white; font-size: 11px;">LEARN MORE ABOUT THE ADMISSION PROCESS</span></a>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="!isWindowOnDesktop">
                <div style="margin-top: 50px;">
                    <div class="container" style="margin-left: 75px; width: 75%;">
                        <div class="row">
                            <div class="col-md-3" style="margin-bottom: 10px; margin-right: 15px;">
                                <div class="card" style="width: 15rem; border-radius: 20px;">
                                    <el-image style="border-radius: 20px; width: 238px; height: 160px;" class="card-img-top" :src="resources.link_images.pre_school" alt="Pre-School" lazy></el-image>
                                    <div class="overlay"></div>
                                    <center><div class="centered-text">Preschool and Grade School</div></center>
                                </div>
                            </div>

                            <div class="col-md-3" style="margin-bottom: 10px; margin-right: 15px;">
                                <div class="card" style="width: 15rem; border-radius: 20px;">
                                    <el-image style="border-radius: 20px; width: 238px; height: 160px;" class="card-img-top" :src="resources.link_images.jhs" alt="Junior High" lazy></el-image>
                                    <div class="overlay"></div>
                                    <center><div class="centered-text">Junior High School</div></center>
                                </div>
                            </div>

                            <div class="col-md-3" style="margin-bottom: 10px; margin-right: 15px;">
                                <div class="card" style="width: 15rem; border-radius: 20px;">
                                    <el-image style="border-radius: 20px; width: 238px; height: 160px;" class="card-img-top" :src="resources.link_images.shs" alt="Senior High" lazy></el-image>
                                    <div class="overlay"></div>
                                    <center><div class="centered-text">Senior High School</div></center>
                                </div>
                            </div>

                            <div class="col-md-3" style="margin-bottom: 10px; margin-right: 15px;">
                                <div class="card" style="width: 15rem; border-radius: 20px;">
                                    <el-image style="border-radius: 20px; width: 238px; height: 160px;" class="card-img-top" :src="resources.link_images.night_secondary" alt="Night Secondary School" lazy></el-image>
                                    <div class="overlay"></div>
                                    <center><div class="centered-text">Night Secondary School</div></center>
                                </div>
                            </div>

                            <div class="col-md-3" style="margin-bottom: 10px; margin-right: 15px;">
                                <div class="card" style="width: 15rem; border-radius: 20px;">
                                    <el-image style="border-radius: 20px; width: 238px; height: 160px;" class="card-img-top" :src="resources.link_images.college" alt="College" lazy></el-image>
                                    <div class="overlay"></div>
                                    <center><div class="centered-text">College</div></center>
                                </div>
                            </div>

                            <div class="col-md-3" style="margin-bottom: 10px; margin-right: 15px;">
                                <div class="card" style="width: 15rem; border-radius: 20px;">
                                    <el-image style="border-radius: 20px; width: 238px; height: 160px;" class="card-img-top" :src="resources.link_images.grad_school" alt="Graduate School" lazt></el-image>
                                    <div class="overlay"></div>
                                    <center><div class="centered-text">Graduate School</div></center>
                                </div>
                            </div>

                            <div class="col-md-3" style="margin-bottom: 10px; margin-right: 15px;">
                                <div class="card" style="width: 15rem; border-radius: 20px;">
                                    <el-image style="border-radius: 20px; width: 238px; height: 160px;" class="card-img-top" :src="resources.link_images.cognate" alt="Cognate Programs" lazy></el-image>
                                    <div class="overlay"></div>
                                    <center><div class="centered-text">Cognate Programs</div></center>
                                </div>
                            </div>

                            <div class="col-md-3" style="margin-bottom: 10px; margin-right: 15px;">
                                <div class="card" style="width: 15rem; border-radius: 20px;">
                                    <el-image style="border-radius: 20px; width: 238px; height: 160px;" class="card-img-top" :src="resources.link_images.ced" alt="CCED" lazy></el-image>
                                    <div class="overlay"></div>
                                    <center><div class="centered-text">Center for Continuing Education and Development</div></center>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container" style="margin-top: 30px; width: 75%;">
                        <div class="row justify-content-center">
                            <center>
                                <div class="col-md-3" style="margin-bottom: 10px;">
                                    <a href="https://sscmanila.orangeapps.ph/landing/sscmanila/" class="rounded-btn btn btn-ssa"><span style="color: white; font-size: 28px;">APPLY NOW</span></a>
                                </div>
                                <div class="col-md-3">
                                    <a :href="env_wp.APP_URL+'/admission'" class="rounded-btn btn btn-ssa" style="margin-left: 5px;"><span style="color: white;">LEARN MORE ABOUT<br/>THE ADMISSION PROCESS</span></a>
                                </div>
                            </center>
                        </div>
                    </div>

                </div>
            </template>
        </div>

        <div style="margin-top: 20px;">
            <center>
                <img :src="resources.announcements.grade_school_open_house_12_13_2024" alt="Grade School Open House 12-13-2024">
            </center>
        </div>

        <div style="margin-top: 20px;">
            <center>
                <div class="justify-content-center" :style="isWindowOnDesktop ? 'background-color: #012376; height: 300px; padding-top: 100px; padding-bottom: 125px;' : 'background-color: #012376; height: 325px; padding-top: 25px; padding-bottom: 125px;'">
                    <h1 :style="isWindowOnDesktop ? 'font-size: 2.5rem; color: white;' : 'font-size: 2rem; color: white;'">WHAT PARENTS AND STUDENTS ARE SAYING</h1>
                    <br>
                    <h4 style="color: white;">
                        From inspiring stories of academic achievements to heartfelt accounts <br>of character development, these testimonials offer a glimpse into the
                        <br> profound influence of the education SSC Manila provides.
                    </h4>
                </div>
                <br>
                <br>
                <div class="container">
                    <h4 :style="!isWindowOnDesktop ? 'font-size: 1.05rem;' : ''">
                        A good foundation is a must in order to achieve any goal in life. My Scholastican Psychology<br>
                        education has given me not only the academic excellence needed to pursue medicine, but also<br>
                        the mental fortitude to withstand challenges in this journey. Because of this, no matter what I<br>
                        achieve in life, I can proudly say that I was first and foremost a Scholastican.<br>
                    </h4>
                    <div style="border-radius: 50%">
                        <img :src="resources.testimonials.nicole" alt="Dr. Nicole L. Reyes" class="image-fluid">
                    </div>
                    <h3 style="font-family: Arial;"><strong>Dr. Nicole L. Reyes</strong></h3>
                    <h4 :style="!isWindowOnDesktop ? 'font-size: 1.05rem;' : ''">BS Psychology Alumna | Batch 2017</h4>
                </div>
                <br>
                <br>
                <div class="container">
                    <h4 :style="!isWindowOnDesktop ? 'font-size: 1.05rem;' : ''">
                        My education at St. Scho has been a transformative journey in my life. It has not only equipped<br>
                        me with academic knowledge but also taught me invaluable life lessons that have instilled in<br>
                        me a sense of social responsibility. I believe that St. Scho is a home of opportunities you never<br>
                        thought possible for you to excel and discover different sides of yourself. This education is a<br>
                        lifelong process that goes beyond knowing facts and figures; it also involves cultivating a<br>
                        passion for learning, embracing new ideas, and continuously expanding one’s horizons.<br>
                    </h4>
                    <div style="border-radius: 50%">
                        <img :src="resources.testimonials.ayessa" alt="Ms. Ayessa Marithe Reloj" class="image-fluid">
                    </div>
                    <h3 style="font-family: Arial;"><strong>Ms. Ayessa Marithe Reloj</strong></h3>
                    <h4 :style="!isWindowOnDesktop ? 'font-size: 1.05rem;' : ''">BS Psychology Alumna | Batch 2023</h4>
                </div>
                <br>
                <br>
                <div class="container">
                    <h4 :style="!isWindowOnDesktop ? 'font-size: 1.05rem;' : ''">
                        “Ora et Labora – these are the words I’ve kept to push myself every day to reach mydream.<br>
                        The Scholastican Psychology Education helped me by going through every challenge with grit,<br>
                        self-compassion and always remembering to accept my humanness.
                    </h4>
                    <div style="border-radius: 50%">
                        <img :src="resources.testimonials.dr_alarca" alt="Dr. Josanne Gabrielle R. Alarca" class="image-fluid">
                    </div>
                    <h3 style="font-family: Arial;"><strong>Dr. Josanne Gabrielle R. Alarca</strong></h3>
                    <h4 :style="!isWindowOnDesktop ? 'font-size: 1.05rem;' : ''">BS Psychology Alumna | Batch 2017</h4>
                </div>
                <br>
                <br>
                <div class="container">
                    <h4 :style="!isWindowOnDesktop ? 'font-size: 1.05rem;' : ''">
                        SSC Manila has a personalized approach to education, which sets it apart. The teachers are<br>
                        not only skilled educators but also adept at active listening and displaying empathy. They hold<br>
                        high expectations for their students while genuinely addressing their academic concerns.<br>
                        Moreover, SSC Manila places great emphasis on cultivating responsible citizenship among its<br>
                        students. The institution fosters awareness about the country’s current affairs and encourages<br>
                        students to actively contribute to the betterment of our nation by instilling a sense of duty.
                    </h4>
                    <div style="border-radius: 50%">
                        <img :src="resources.testimonials.joan" alt="Mrs. Ann Joan Hope V. Caparas" class="image-fluid">
                    </div>
                    <h3 style="font-family: Arial;"><strong>Mrs. Ann Joan Hope V. Caparas</strong></h3>
                    <h4 :style="!isWindowOnDesktop ? 'font-size: 1.05rem;' : ''">High School Parent</h4>
                </div>
                <br>
                <br>
                <div class="container">
                    <h4 :style="!isWindowOnDesktop ? 'font-size: 1.05rem;' : ''">
                        What does it really mean to be called a true blue – blooded Scholastican? My fellow graduates,<br>
                        true blue is not a question of time but of spirit. It does not depend on the number of years we<br>
                        have stayed in St. Scholastica’s College.
                    </h4>
                    <div style="border-radius: 50%">
                        <img :src="resources.testimonials.julia" alt="Julia Angela Marie Villa-Ignacio" class="image-fluid">
                    </div>
                    <h3 style="font-family: Arial;"><strong>Julia Angela Marie Villa-Ignacio</strong></h3>
                    <h4 :style="!isWindowOnDesktop ? 'font-size: 1.05rem;' : ''">High School Alumna | Batch 2023</h4>
                </div>
            </center>
        </div>
        <br>
        <div style="margin-top: 50px;">
            <center>
                <div class="justify-content-center" style="">
                    <h1 style="font-size: 2rem; color: #012376;"><strong>FEATURED ALUMNAE</strong></h1>
                    <h4 style="color: #012376;">
                        Get to know the notable Schoalsticans who have made their mark in their respective<br>
                        fields and in the society.
                    </h4>
                </div>
                <br>
                <br>
                <div class="container">
                    <div style="border-radius: 50%">
                        <img :src="resources.alumni.pres_cory" alt="Late President Corazon Aquino" class="image-fluid" :style="!isWindowOnDesktop ? 'width: 75%; height: 75%' : ''">
                    </div>
                    <h3 style="font-family: Arial; color: #012376;"><strong>Late President Corazon Aquino</strong></h3>
                    <h4 style="font-family: Arial; color: #012376;">GRADE SCHOOL '43</h4>
                </div>
                <br>
                <div class="container">
                    <div style="border-radius: 50%">
                        <img :src="resources.alumni.dolly" alt="Dolly De Leon" class="image-fluid" :style="!isWindowOnDesktop ? 'width: 75%; height: 75%' : ''">
                    </div>
                    <h3 style="font-family: Arial; color: #012376;"><strong>Dolly De Leon</strong></h3>
                    <h4 style="font-family: Arial; color: #012376;">HIGH SCHOOL '86</h4>
                </div>
                <br>
                <div class="container">
                    <div style="border-radius: 50%">
                        <img :src="resources.alumni.agnes" alt="Agnes Reoma" class="image-fluid" :style="!isWindowOnDesktop ? 'width: 75%; height: 75%' : ''">
                    </div>
                    <h3 style="font-family: Arial; color: #012376;"><strong>Agnes Reoma</strong></h3>
                    <h4 style="font-family: Arial; color: #012376;">COLLEGE '18</h4>
                </div>
            </center>
        </div>

        <div style="margin-top: 50px;">
            <template v-if="isWindowOnDesktop">
                <div class="row justify-content-center">
                    <div class="col-md-6">
                        <div style="padding-left: 25px;">
                            <iframe width="600" height="325" src="https://www.youtube.com/embed/Wl0rfGIcUkU" title="St. Scholastica's College Manila: Pundasyon" loading="lazy" allowfullscreen frameborder="0"></iframe>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div style="padding-left: 100px;">
                            <h1 style="font-size: 2rem; color: #012376; font-family: Arial;"><strong>What Makes a Scholastican?</strong></h1>
                            <br>
                            <h4 style="font-family: Arial;">
                                Watch the new <strong>Empowering Students for<br>
                                Change</strong> campaign video Entitled <i>"Pundasyon"</i>
                            </h4>
                            <br>
                            <p style="font-family: Arial;">
                                Get to know our Scholasticans and learn more about St. Scho<br>
                                Manila and its commitment to empowering students for<br>
                                change. The video features our Scholastican Ambassadors for<br>
                                2023: Senator Risa Hontiveros, Patricia Lasaten, Michelle Cobb,<br>
                                Selene L. Yu, Triciah Terada, Idr. Wilhelmina Garcia, and Maria<br>
                                Aurora Carandang Gloria.
                            </p>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="!isWindowOnDesktop" class="pull-left">
                <center>
                    <h1 style="font-size: 2rem; color: #012376; font-family: Arial;"><strong>What Makes a Scholastican?</strong></h1>
                    <br>
                    <h4 style="font-family: Arial;">
                        Watch the new <strong>Empowering Students for<br>
                        Change</strong> campaign video Entitled <i>"Pundasyon"</i>
                    </h4>
                    <br>
                    <iframe width="400" height="175" src="https://www.youtube.com/embed/Wl0rfGIcUkU" title="St. Scholastica's College Manila: Pundasyon" loading="lazy" allowfullscreen frameborder="0"></iframe>
                    <br>
                    <p style="font-family: Arial;">
                        Get to know our Scholasticans and learn more about St. Scho<br>
                        Manila and its commitment to empowering students for<br>
                        change. The video features our Scholastican Ambassadors for<br>
                        2023: Senator Risa Hontiveros, Patricia Lasaten, Michelle Cobb,<br>
                        Selene L. Yu, Triciah Terada, Idr. Wilhelmina Garcia, and Maria<br>
                        Aurora Carandang Gloria.
                    </p>
                </center>
            </template>
        </div>

        <div style="margin-top: 20px; margin-bottom: 20px;">
            <div class="row" :style="!isWindowOnDesktop ? 'margin-left: 0; margin-right: 0;' : ''">
                <div :class="!isWindowOnDesktop ? 'col-md-2' : 'col-md-6'" style="padding-right: 0px;">
                    &nbsp;
                </div>
                <div :class="!isWindowOnDesktop ? 'col-md-2' : 'col-md-6'" :style="!isWindowOnDesktop ? 'padding-right: 0px;' : ''">
                    <button @click="scrollToTop()" class="btn btn-ssa pull-right"><span style="font-family: Arial; font-size: 2rem; color: #ffffff;"><strong><i class="fa fa-angle-double-up" aria-hidden="true"></i>Back to Top</strong></span></button>
                </div>
            </div>
        </div>

        <div style="margin-top: 40px; margin-bottom: 0px;">
            <div class="row justify-content-center" :style="!isWindowOnDesktop ? 'margin-left: 0; margin-right: 0;' : ''">
                <div class="col-md-6" :style="!isWindowOnDesktop ? 'margin-top: 100px; margin-right: 20px' : 'margin-top: 100px;'">
                    <div class="row">
                        <div :class="!isWindowOnDesktop ? 'col-md-2' : 'col-md-6'" :style="!isWindowOnDesktop ? 'padding-right: 0px;' : ''">
                            <center>
                                <h1 style="font-size: 3.5rem; color: #012376; font-family: Arial; margin-bottom: 5px;"><strong>258</strong></h1>
                                <p style="font-family: Arial; margin-top: 0;">LAY MISSION PARTNERS</p>
                            </center>
                        </div>
                        <div :class="!isWindowOnDesktop ? 'col-md-2' : 'col-md-6'" :style="!isWindowOnDesktop ? 'padding-right: 0px;' : ''">
                            <center>
                                <h1 style="font-size: 3.5rem; color: #012376; font-family: Arial; margin-bottom: 5px;"><strong>66</strong></h1>
                                <p style="font-family: Arial; margin-top: 0;">PAASCU ACCREDITED YEARS</p>
                            </center>
                        </div>
                        <div class="w-100"></div>
                        <div :class="!isWindowOnDesktop ? 'col-md-2' : 'col-md-6'" :style="!isWindowOnDesktop ? 'padding-right: 0px;' : ''">
                            <center>
                                <h1 style="font-size: 3.5rem; color: #012376; font-family: Arial; margin-bottom: 5px;"><strong>117</strong></h1>
                                <p style="font-family: Arial; margin-top: 0;">YEARS ESTABLISHED</p>
                            </center>
                        </div>
                        <div :class="!isWindowOnDesktop ? 'col-md-2' : 'col-md-6'" :style="!isWindowOnDesktop ? 'padding-right: 0px;' : ''">
                            <center>
                                <h1 style="font-size: 3.5rem; color: #012376; font-family: Arial; margin-bottom: 5px;"><strong>&nbsp;</strong></h1>
                                <p style="font-family: Arial; margin-top: 0;">&nbsp;</p>
                            </center>
                        </div>
                    </div>
                </div>
                <div :class="!isWindowOnDesktop ? 'col-md-2' : 'col-md-6'" :style="!isWindowOnDesktop ? 'padding-left: 0px; padding-right: 0px;' : ''">
                    <img style="width: 100%; height: 100%;" :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/hompage/ssc_manila_population_count_assets/DSC03970 (2) (1).jpg'" alt="Scholasticans">
                </div>
            </div>
        </div>
        <!--CONTENTS END-->

        <!--FOOTER START-->
        <div style="background: #012376;">
            <div class="row" :style="!isWindowOnDesktop ? 'margin-left: 0; margin-right: 0;' : ''">
                &nbsp;
            </div>
        </div>
        <SiteFooter :style="{backgroundImage: `url('${env_wp.APP_URL}/public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_backdrop.png')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%', paddingRight: 0}"></SiteFooter>
        <!--FOOTER END-->
    </div>
</template>
<style>
    .el-carousel__item h3 {
        display: flex;
        color: #475669;
        opacity: 5;
        line-height: 300px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #012376;
        border-radius: 20px;
        opacity: 75%;
    }

    .centered-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 16px;
        font-weight: bold;
        z-index: 1;
    }
    .btn-ssa{
        background-color: #012376;
    }

</style>
<script>
    import NavbarStatic from "../../../components/general/NavbarStatic";
    import SiteHeader from "../../../components/general/SiteHeader";
    import SiteFooter from "../../../components/general/SiteFooter";
    export default {
        props: {
            resources: Array
        },
        components: { NavbarStatic,SiteHeader,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp,
                screenWidth: 0,
                isWindowOnDesktop: true,
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize)
            });
            this.onResize();
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        watch: {
            screenWidth(width) {
                if (width > 768) {
                    this.isWindowOnDesktop = true;
                    //this.css = 'mt-3 px-5';
                } else {
                    this.isWindowOnDesktop = false;
                    //this.css = 'mt-3 px-3';
                }
            },
        },
        created() {

        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            onResize(event) {
                this.screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            },
        }
    }
</script>