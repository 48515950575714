<template>
    <div>
        <!--HEADER NAVBAR START-->
        <SiteHeader style="width: 100%"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <!--HEADER NAVBAR END-->

        <!--CONTENTS START-->
        <div class="container">
            <div class="header">
                <h1 style="color: #012376; font-size: 3rem; font-family: Arial; margin-bottom: 50px;"><strong>NIGHT SECONDARY SCHOOL</strong></h1>
                <!-- <h1 style="color: #012376; font-size: 3rem; font-family: Arial"><strong><i>ABOUT OUR GRADUATE SCHOOL PROGRAMS</i></strong></h1> -->
                <!-- <p style="color: #012376; font-size: 1.5rem; font-family: Arial">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p> -->
            </div>

            <div class="justify-content-start">
                <h1 style="color: #012376; font-size: 3rem; font-family: Arial"><strong><i>HISTORY OF NIGHT SECONDARY SCHOOL</i></strong></h1>
                <br>
                <h4 class="text-left" style="color: #012376; font-size: 1.5rem;">
                    &nbsp;&nbsp;&nbsp;St. Scholastica’s College (SSC) was founded on December 3, 1906 by five (5) German Sisters headed by Sister Ferdinanda  Hoelzer, OSB. In a small residential house in Tondo, the Sisters started their educational apostolate with six (6) paying students and fifty (50) in free section. The Sisters labored to enable students to learn and imbibe the Benedictine ideals of “Ora et Labora”, or prayer and work. As they continued the mission of providing education to the poor but deserving children, a free elementary school was put up when the Sisters moved to Leon Guinto Street, its present site. The said free school continued until such time the Sisters no longer saw its need due to the government taking more active role in fulfilling its constitutional mandate of providing primary education. With the opening of more public elementary schools, the Sisters saw their new role – that of providing additional education and training beyond grade school to those who needed it.
                </h4>
                <br>
                <br>
                <h4 class="text-left" style="color: #012376; font-size: 1.5rem;">
                    &nbsp;&nbsp;&nbsp;Towards the end of the decade of the sixties, the Manila Community of the Missionary Benedictine Sisters of St. Scholastica’s College saw the need to extend free secondary education to young women who have been forced to leave school due to economic reasons. At that time, the sisters were in the process of closing the free school (elementary). Thus, opening of a free terminal-vocational program was seen as a welcome move in terms of the Sisters continuing their educational apostolate.
                </h4>
                <br>
                <br>
                <h4 class="text-left" style="color: #012376; font-size: 1.5rem;">
                    &nbsp;&nbsp;&nbsp;Starting in the latter part of August 1973 the school’s Adult Education program was born.  This program is now known as the Night Secondary School.   It has an initial enrollment of sixteen (16) students which were all helpers working in the sister’s community. Within the same month, August 11, 1970 the program was granted by the Bureau of Private Schools. Among the program’s first teachers were Sr. Editha Wydorski, OSB (English Composition and Literature, Religion and Character Education), Sr. Christophora Rettinger, OSB (Arithmetic), Sr. Guadalupe Valdes, OSB (Filipino),                         Sr Roberta Ilumin, OSB (Filipino), Sr. Monica Tomista, OSB (Basic Foods) and Ms. Prudentia Mapa (Vocational Education). The following school year (1970-71), applications for incoming freshwomen were opened to helpers of alumnae, teachers, school employees and students who reside near the school. Age requirement then was seventeen (17) years old.  Supervising the program in its earlier years was Sr. M. Simeona Ricalde, OSB.
                </h4>
                <br>
                <br>
                <h4 class="text-left" style="color: #012376; font-size: 1.5rem;">
                    &nbsp;&nbsp;&nbsp;The adult education program or night secondary course was envisioned by the sisters as five-year “free” terminal-vocational program for underprivileged girl/women who were forced to stop their schooling due to financial constraints. Its initial purpose and objective was “to help poor young women and adults, especially household helps, gain better education in life as well as improve in or learn a vocation for a better and more productive work or employment”. The program initially catered and generally still caters to the “manangs” working in the school, household helps of alumnae and faculty, less privileged girls/women residing in the vicinity of the school and/or working girls/women. The pool of teachers were and continue to be made up of sisters, day teachers/office personnel and those coming from the outside.
                </h4>
                <br>
                <br>
                <h4 class="text-left" style="color: #012376; font-size: 1.5rem;">
                    &nbsp;&nbsp;&nbsp;Today, the NSS continues to serve as an avenue for the fulfillment of the educational apostolate of the Benedictine sisters. With an initial enrollment of sixteen (16) students when it opened its doors in 1970, it has now witnessed the graduation of hundreds of students and are now successful in their own field.
                </h4>
            </div>

            <br>
            <br>

            <div class="justify-content-start">
                <h1 style="color: #012376; font-size: 3rem; font-family: Arial;">
                    <strong><i>VISION</i></strong>
                </h1>
                <br>
                <h4 class="text-left" style="color: #012376; font-size: 1.5rem;">
                    &nbsp;&nbsp;&nbsp;Night Secondary School envisions graduates who are technically competent, well-balanced, Christ-centered girls and women equipped with quality Scholastican education who emulate simplicity of lifestyle, compassion and purpose-driven life and are ready for gainful employment or pursuit of further studies.
                </h4>
            </div>
            
            <br>
            <br>

            <div class="justify-content-start">
                <h1 style="color: #012376; font-size: 3rem; font-family: Arial;">
                    <strong><i>MISSION</i></strong>
                </h1>
                <br>
                <h4 class="text-left" style="color: #012376; font-size: 1.5rem;">
                    &nbsp;&nbsp;&nbsp;The Night Secondary School provides five years secondary education with vocational, technical, hands-on training to girls and women who are economically-challenged and unable to pursue secondary education.
                </h4>
            </div>

            <br>
            <br>
            
            <div class="justify-content-start">
                <h1 style="color: #012376; font-size: 3rem; font-family: Arial;">
                    <strong><i>GOALS</i></strong>
                </h1>
                <br>
                <h4 style="color: #012376; font-size: 1.5rem;">
                    To fulfill its mission and vision, NSS shall:
                </h4>
                <br>
                <ol style="color: #012376; font-size: 1.3rem;">
                    <li>Equip students with adequate communication and technical skills;</li>
                    <li>Ensure that curricular, co-curricular and extra-curricular programs develop a social-orientation and critical awareness of present realities;</li>
                    <li>Facilitate formation of persons leading to a well-balanced individual;</li>
                    <li>Create conditions to experience genuine care for the environment; and</li>
                    <li>Organize activities to enhance the understanding and living out the Benedictine values.</li>
                </ol>
            </div>

            <br>
            <br>

            <div class="justify-content-start">
                <h1 style="color: #012376; font-size: 3rem; font-family: Arial;">
                    <strong><i>ADMISSION REQUIREMENTS</i></strong>
                </h1>
                <br>
                <ol style="color: #012376; font-size: 1.3rem;">
                    <li>Present copy of the latest report card.</li>
                    <li>Secure application and recommendation forms from the NSS Office.  No testing fee.</li>
                    <li>
                        Submit the following requirements:
                        <ol style="color: #012376; font-size: 1.3rem;" type="a">
                            <li>3 recommendation forms from the Principal, Adviser and Counselor.</li>
                            <li>Latest copy of Form 138 (Report Card)</li>
                            <li>Good Moral Character from previous school</li>
                            <li>PSA Birth Certificate (Original and Photocopy)</li>
                            <li>Baptismal Certificate (Original and Photocopy)</li>
                            <li>Accomplished NSS Student Health Record Form</li>
                            <li>Accomplished NSS application form</li>
                            <li>1 x 1 picture</li>
                            <li>Financially challenged certified by the Barangay and ascertained by the Admissions Committee.</li>
                        </ol>
                    </li>
                </ol>
            </div>

            <div class="container" style="margin-top: 150px; margin-bottom: 150px;">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <h1 class="text-left justify-content-start ssa-text-color" style="font-family: Arial; font-size: 5rem; margin-bottom: 25px;">CONTACT US:</h1>
                        <h4 class="text-left justify-content-start ssa-text-color" style="font-size: 2rem;">
                            <strong>Night Secondary School</strong><br>
                            85677686 local 3388 or 3389 ; 09692571332<br>
                            Look for Mrs. Cely Solomon
                        </h4>
                    </div>
                </div>
            </div>

            <div class="container" style="margin-top: 150px; margin-bottom: 150px;">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <h1 class="text-left justify-content-start ssa-text-color" style="font-family: Arial; font-size: 5rem; margin-bottom: 25px;">OPERATING HOURS:</h1>
                        <h4 class="text-left justify-content-start ssa-text-color" style="font-size: 2rem;">
                            <strong>12:00 - 7:00 pm (Monday to Friday)</strong><br>
                            <strong>8:00 - 11:00 am (Saturday)</strong>
                        </h4>
                    </div>
                </div>
            </div>
        </div>

        <div style="margin-top: 150px;">
            &nbsp;
        </div>
        <!--CONTENTS END-->

        <!--FOOTER START-->
        <div class="footer-banner-container">
            <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/pre_school_kinder/footer_banner2.png'">
        </div>
<!--        <div style="background: #012376;">-->
<!--            <div class="row">-->
<!--                &nbsp;-->
<!--            </div>-->
<!--        </div>-->
        <SiteFooter :style="{backgroundImage: `url('${env_wp.APP_URL}/public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_backdrop.png')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%'}"></SiteFooter>
        <!--FOOTER END-->
    </div>
</template>
<style>
    .ssa-card{
        background-color: #012376;
        height: 150px;
    }

    .el-carousel__item h3 {
        display: flex;
        color: #475669;
        opacity: 5;
        line-height: 300px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #012376;
        border-radius: 20px;
        opacity: 75%;
    }

    .centered-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 16px;
        font-weight: bold;
        z-index: 1;
    }
    .btn-ssa{
        background-color: #012376;
    }

    .school-section {
        padding: 20px 0;
        background-color: #f8f9fa;
    }
    .school-card {
        margin-bottom: 20px;
    }
    .school-card img {
        width: 100%;
        height: auto;
    }
    .school-card .card-body {
        background-color: #002e6e;
        color: white;
    }

    .footer-banner-container {
        position: relative;
        width: 100%;
        padding-top: 36.52%; /* Aspect ratio (328 / 898 * 100%) */
        background-color: #002d72; /* Fallback background color */
    }

    .footer-banner-container img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 2px solid white;
        border-radius: 5px;
    }


    .header {
        text-align: center;
        margin: 20px 0;
    }
    .header h1 {
        font-size: 2.5rem;
    }
    .header h3 {
        font-size: 1.5rem;
        margin-top: 10px;
    }
    .header p {
        font-size: 1rem;
        margin-top: 10px;
    }
    .school-section {
        margin-top: 20px;
    }
    .school-card2 {
        position: relative;
        color: white;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 20px;
        padding: 20px;
        /*background-color: blue;*/
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: left;
    }
    .school-card2 .backdrop2 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*background: rgba(0, 0, 0, 0.5);*/
        /*background: linear-gradient(to left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1));*/
        z-index: 1;
    }
    .school-card2 .content {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
    }
    .school-card2 img.logo {
        width: 175px;
        height: 175px;
        margin-right: 10px;
    }
    .school-card2 .text {
        flex: 1;
    }
    .school-card2 .card-title4 {
        font-size: 3rem;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0;
        float: right;
    }
    .school-card2 .card-title5 {
        font-size: 2.5rem;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0;
        float: right;
    }
    .school-card2 .card-title6 {
        font-size: 1.75rem;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0;
        float: right;
    }
    .school-card2 .description {
        margin-top: 5px;
    }
    .school-card2 img.background {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 10;
        z-index: 1;
    }
</style>
<script>
    import NavbarStatic from "../../../components/general/NavbarStatic";
    import SiteHeader from "../../../components/general/SiteHeader";
    import SiteFooter from "../../../components/general/SiteFooter";
    export default {
        props: {
            resources: Array
        },
        components: { NavbarStatic,SiteHeader,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp,
                screenWidth: 0,
                isWindowOnDesktop: true,
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize)
            });
            this.onResize();
        },
        created() {

        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        watch: {
            screenWidth(width) {
                if (width > 768) {
                    this.isWindowOnDesktop = true;
                    //this.css = 'mt-3 px-5';
                } else {
                    this.isWindowOnDesktop = false;
                    //this.css = 'mt-3 px-3';
                }
            },
        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            onResize(event) {
                this.screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            },
        }
    }
</script>